
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;600&display=swap');


:root {
  --font-base: "Inconsolata", sans-serif; 
  --primary-color: #111;
  --secondary-color: #118a53;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --white-color: #ffffff;
  --green-primary: #0acc74;
  --green-secondary: #118a53;
} 

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;  
  overscroll-behavior: none;
}




