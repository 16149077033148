$theme-map: null;

$themes: (
  light: (
    textColor: white,
    bg: 	#fafafa,
    button: #0acc74,
    bgSoft: #f6f3f3,
    textColorSoft: #118a53,
    whiteGreen: #0acc74,
    color: #000,
    hover: #0acc74
  ),
  dark: (
    textColor: black,
    bg: #111,
    button: white,
    bgSoft: #333,
    textColorSoft: #118a53,
    whiteGreen: whitesmoke,
    color: whitesmoke,
    hover: #0acc74,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 960px) {
    @content;
  }
}


.app {
    @include themify($themes) {
    background-color: var(--primary-color);
    font-family: var(--font-base);
    background-color: themed("bg");
    color: themed("textColor");
    }
  }


  
  .app__container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
  }
  
  .app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .app__wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;
    justify-content: flex-start;
    margin-top:30px;
    
  
    @media screen and (max-width: 450px) {
      padding: 5rem 1rem 4rem 1rem;
      margin:0;
    }
  }

  .app__head-text {
    text-align: center;
    
   
    align-self: center;
    padding: 10px;
    color: var(--green-primary);
    @media screen and (max-width: 450px) {
      font-size: 1rem;
    }
}

  .head-text {
    @include themify ($themes){
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    color: themed(color);
  
    span {
      color: var(--green-primary);
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 4rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
  }
}
  
  .p-text {
    @include themify ($themes) {
    font-size: 0.8rem;
    text-align: center;
    color: themed("textColor");
    line-height: 1.5;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem;
    }
  }
}


.p-text-secondary {
  @include themify ($themes) {
  font-size: 0.8rem;
  text-align: center;
  color: black;
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}
}
  
  .bold-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--black-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
    }
  }
  
  .app__social {

    @include themify ($themes) {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom:0px;
    margin: 0px;
    padding-left: 2rem;
    gap:0.5rem;
    

    .app__vertical-line-left {
      border-left: 1px solid;
      color: themed("color");
      align-self: center;
      margin-top: 10px;
      height: 100px;
    }

    @include mobile {
      display:none;
    }
  
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: themed("color");
      margin: 0.25rem 0;
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 15px;
        height: 15px;
        color: var(--gray-color);
      }
  
      &:hover {
        background-color: themed("hover");
        
  
        svg {
          color: var(--white-color);
        }
        
      }
    
      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 30px;
          height: 30px;
        }
        
      }
    }
  }
}
  .app__rightbar {
    @include themify ($themes) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    right: 0;
    bottom:0px;
    margin: 0px;
    padding-right: 2rem;
  
    @include mobile {
      display:none;
    }
    

    .app__rightbar-email {
      writing-mode: vertical-rl;
      text-decoration: none;
      font-size: 0.85rem;
      letter-spacing: 1px;
      color: themed("color");  
    }

    .app__rightbar-email:hover {
      color: var(--green-primary);
    }
  
    .app__vertical-line-right  {
      right: 0;
      border-left: 1px solid;
      align-self: center;
      height: 100px;
      margin-top: 10px;
      color: themed("color");
      
    }
  }
}

  .app__button-primary {
    color: white;
    background-image: linear-gradient(to right, #118a53 0%, #93F9B9  90%, #0acc74  100%);
    font-size: 1.2rem;
    background-size: 200% auto;
    text-decoration: none;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;

  @media screen and (max-width: 768px) {
  font-size: 0.85rem;
  padding: 5px;
  margin-bottom: 10px;
  background-color: transparent;
  z-index: 3;
  }
}
 

