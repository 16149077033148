@import "../../App.scss";

.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 0 10px 0 10px;
  }
  
  .app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
  
    margin: 1rem 0 2rem;
  
    .app__work-filter-item {
      font-size: 1rem;
      padding: .5rem 2rem;
      border: none;
      outline: none;
      position: relative;
      border-radius: 5px;
      background: linear-gradient(to right, #00FFA3, #118a53);
      cursor: pointer;
      font-weight: 500;
      font-family: var(--font-base);
      z-index: 1;
    
      transition: all 0.3s ease;
      margin: 0.5rem;

        &::before {
        content: "";
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        background-color: #111;
        border-radius: 4px;
        z-index: -1;
        transition: 200ms;
      } 

      &::after {
        content: attr(data);
        background: linear-gradient(to left, #00FFA3, #118a53);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }

      
  
      &:hover::before {
        opacity: 50%;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    &:hover::after {
      color:white;
    }
  
      @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
      }
    }
  
    .item-active::before  {
      background: linear-gradient(to right, #00FFA3, #118a53);
  }

  .item-active::after {
    color:white;
  }
}


  
  .app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  
    .app__work-item {

    @include themify ($themes) {
      width: 300px;
      height: 420px;
      flex-direction: column;
      margin: 2rem;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: themed("color");
      box-shadow: 0 7px 20px 5px #00000088;

      color: #000;
      
  
      
      transition: all 0.3s ease;
  
      &:hover {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
      }
  
      @media screen and (min-width: 2000px) {
        width: 470px;
        padding: 1.25rem;
        border-radius: 0.75rem;
      }
  
      @media screen and (max-width: 768px) {
        width: 100%;
        margin:2rem 0rem 0rem 0rem;
        height: auto;
      }
      @media screen and (min-width: 768px) and (max-width: 880pxc) {
        width: 80%;
        height: auto;
      }
    }
  }
}
  
  .app__work-img {
    width: 100%;
    height: 230px;
    position: relative;
   
  
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      object-fit: cover;

    }
  
    @media screen and (min-width: 2000px) {
      height: 350px;
    }
    
  }
  
  .app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    @media (hover: none) {
      opacity: 90%;
      
    }
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
  
      margin: 1rem;
      
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
  
      svg {
        width: 50%;
        height: 50%;
        color: var(--white-color);
      }
    }
  }
  
  .app__work-content {
    @include themify ($themes) {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    
  
    h2 {
      margin-top: 1rem;
      line-height: 1.5;
      color:themed("textColor");
  
      @media screen and (min-width: 2000px) {
        margin-top: 3rem;
      }
    }
    }
  
    .app__work-tag {
        @include themify ($themes) {
      position: absolute;
      padding: 0.5rem 1rem;
      border-radius: 10px;
      background: linear-gradient(to right, #00FFA3, #118a53);
      top: -25px;
      color: white;
      
      
    }
  }
}


   
