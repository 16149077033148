@import "../../App.scss";

.app__about {
    @include themify($themes) {
    padding: 0 10px 0 10px;
    max-width: 100%;
    color: themed("color");
    gap:50px;   
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; 
    align-items: center;
    
    @media screen and (max-width: 1100px) {
        width: 100%;
        flex-wrap: wrap;
        gap:5px; 
    }

    svg {
        color: var(--green-primary);
    }
}
}

.app__about-text-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 70%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.app__about-text-paragraph {
    font-size: 1.3rem;
    line-height: 1.3;
    font-weight: 200;
    background-color: #162c1b;
    color:whitesmoke;
    padding:10px 20px;
    border-radius: 5px;
    text-align: center;
    margin: 20px 0 10px;
    @media screen and (max-width: 450px) {
        font-size: 0.85rem;
        line-height: 1.5;
}
}

.app__button-primary {
    text-align: center;
}

.app__skills-container {
    display: flex;
    flex-direction: column;
}

.app__skill-wrapper2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.figure.app__skill-wrapper {
    display: flex;
    text-align: center;
    width: 120px;
}

.app__skill-image {
    width: 5rem;
    padding: 5px;
}
.app__skills-caption {
    text-align: center;
}

.read-more-btn {
    border:none;
    background-color: transparent;
    color:var(--green-primary);
    cursor: pointer;
    font-family: var(--font-base);
    text-transform: capitalize;
}
