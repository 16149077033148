@import "../../App.scss";

.app__header-container {
    @include themify($themes) {
    padding: 130px 10px 0 10px;
    max-width: 100%;
    color: themed("color");
    gap:50px;    
    align-items: end;
    @media screen and (max-width: 865px) {
        width: 100%;
        padding: 50px 20px 0 20px;
        flex-wrap: wrap;
        gap:10px;
        text-align: center;    
    }
}
}
    
span {
    color: var(--green-primary);
}


.app__header-text-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media screen and (max-width: 865px) {
        width: 100%;
        align-items: center;
    }
}


.app__header-text {
    font-size: 2.8rem;
    font-weight: 300;
    text-align: center;
    align-self:flex-start;
    background-color: #162c1b;
    color:var(--green-primary);
    padding: 5px 5px;
    display: inline-block;
    margin-bottom: 1rem;
    border-radius: 10px;
    @media screen and (max-width: 865px) {
        font-size: 1.5rem;
        align-self: center;
    }
}

.app__header-text-smaller {
    font-size: 2.2rem;
    font-weight: 400;
    padding-bottom: 10px;
    @media screen and (max-width: 1210px) {
        font-size: 1.3rem;
        padding-bottom: 5px;
    }
    @media screen and (max-width: 768px) {
        font-size: 1rem;
        padding-bottom: 5px;
    }
}

.app__header-text-paragraph {
 font-size: 1.3rem;
 font-weight: 200;
 margin-bottom: 15px;
 line-height: 1.3;
 @media screen and (max-width: 768px) {
    font-size: 0.85rem;
    line-height: 1.5;
}
}



.app__person {
    position: relative;
    width: 200px;
    img {
        max-width: 100%;
    }
    @media screen and (max-width: 500px) {
        top:-40px;
    }
}

.app__person-container {
    
    display: flex;
    align-items: flex-end;
    border-radius: 0 0 100vw 100vw;
    overflow: hidden;
    transition: transform 250ms ease-in-out;
    @media screen and (max-width: 768px) {
        transition: none;
    }
}

.app__person-container:hover {
    transform: scale(1.1);
    @media screen and (max-width: 768px) {
        transform: none;
    }
}

.app__person-circle {
    @include themify($themes) {
    position:absolute;
    background-color: themed("color");
    border-radius: 50%;
    height: 200px;
    width: 100%;
    object-fit: cover;
    margin-inline:auto;
    }
    
}
.app__person-img {
    position:relative;
    top:20px;
    z-index: 2;
    
    transition: transform 250ms ease-in-out;
    @media screen and (max-width: 768px) {
        transition: none;
    }
}

.app__person-container:hover .app__person-img {
    transform: translateY(-1rem);
    @media screen and (max-width: 768px) {
        transform: none;
    }
  }
  .app__anchor-tag{
    width: 10rem;
    text-align: center;
    font-weight: 200;
    padding: 10px;
    text-decoration: none;
    color: white;
    background-image: linear-gradient(to right, #118a53 0%, #93F9B9  90%, #0acc74  100%);
    background-size: 200% auto;
    font-size: 1.2rem;
    border: none;
    
    
    cursor: pointer;
    
    @media screen and (max-width: 990px) {
        z-index: 3;
        width: 8rem;
        font-size: 0.85rem;
    }
  }

  